export var CustomEventType;
(function (CustomEventType) {
    CustomEventType["BUTTON_CLICKED"] = "BUTTON_CLICKED";
    CustomEventType["CHART_DATA"] = "CHART_DATA";
    CustomEventType["NAVIGATE"] = "NAVIGATE";
    CustomEventType["COUNT_CHANGED"] = "COUNT_CHANGED";
    CustomEventType["LOAD"] = "LOAD";
    CustomEventType["ERROR"] = "ERROR";
    CustomEventType["REQUEST_REFRESH"] = "REQUEST_REFRESH";
    CustomEventType["FILTERS_CHANGED"] = "FILTERS_CHANGED";
})(CustomEventType || (CustomEventType = {}));
