var _a;
import { useEffect } from "react";
import { CustomEventBusSchema } from "../types/CustomEventBus";
import { CustomEventType } from "../types/CustomEventType";
document["previousCalls"] || (document["previousCalls"] = (_a = {},
    _a[CustomEventType.BUTTON_CLICKED] = null,
    _a[CustomEventType.CHART_DATA] = null,
    _a[CustomEventType.NAVIGATE] = null,
    _a[CustomEventType.COUNT_CHANGED] = null,
    _a[CustomEventType.LOAD] = null,
    _a[CustomEventType.ERROR] = null,
    _a[CustomEventType.REQUEST_REFRESH] = null,
    _a[CustomEventType.FILTERS_CHANGED] = null,
    _a));
/**
 * Usage:
 *  ******Listening to an event: **********
 * import { useCustomEventBusListener } from 'hooks/useEventBus';
 *
 * useCustomEventBusListener("BUTTON_CLICKED", (event) => {
 *  console.log(event.payload.buttonId);
 * });
 **/
export function useCustomEventBusListener(eventType, callback) {
    useEffect(function () {
        var eventListener = function (event) {
            callback(event.detail);
        };
        // If the event was dispatched before the listener was added, call the listener immediately
        if (document["previousCalls"][eventType]) {
            callback(document["previousCalls"][eventType]);
        }
        self.addEventListener(eventType, eventListener);
        return function () { return self.removeEventListener(eventType, eventListener); };
    }, [eventType]);
}
export function useEventBusLastCall(eventType) {
    return document["previousCalls"][eventType];
}
/**
  * Usage:
  *  *****Dispatching an event: **********
  * import { dispatchCustomEvent } from 'hooks/useEventBus';
  *
  * export const ButtonComponent: React.FC<ButtonComponentProps> = ({ buttonId }) => {
  *  const handleClick = () => {
  *   dispatchCustomEvent({ type: "BUTTON_CLICKED", payload: { buttonId } });
  * };

  * return <button onClick={handleClick}>Click me!</button>;
 **/
export function dispatchCustomEvent(event) {
    CustomEventBusSchema.parse(event); // Validates the event
    self.dispatchEvent(new CustomEvent(event.type, { detail: event }));
    // stores the event in the browser's event cache, so if the event is dispatched before the listener is added, the listener will still be called
    document["previousCalls"][event.type] = event;
}
export function cleanupPreviousCalls() {
    Object.keys(document["previousCalls"]).forEach(function (eventType) {
        document["previousCalls"][eventType] = null;
    });
}
