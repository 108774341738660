var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { FilterEntrypoint } from "./app/app";
import { FetchHTTPClient } from "./app/modules/shared/httpClient/impl/fetchHttpClient";
import { AuthenticatedSPA } from "@dws/dws-auth";
import { CONSTANTS } from "./constants";
var httpClient = new FetchHTTPClient();
export var lifecycles = singleSpaReact({
    React: React,
    ReactDOM: ReactDOM,
    rootComponent: function () { return (_jsx(AuthenticatedSPA, __assign({ clientId: CONSTANTS.AUTH.CLIENT_ID }, { children: _jsx(FilterEntrypoint, { httpClient: httpClient }) }))); },
});
lifecycles.unmount = function () { return Promise.resolve(); };
export var bootstrap = lifecycles.bootstrap, mount = lifecycles.mount, unmount = lifecycles.unmount;
export default lifecycles;
