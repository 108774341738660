var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { z } from "zod";
import { createContext, useContext, useEffect, useMemo, useReducer, useState, } from "react";
import { dependencies } from "../filterOptions/effects";
import { FilterNameSchema, FiltersSchemaUnion, FiltersSchema, } from "shared-schemas/filters";
import { getIdsAndLabels } from "shared-schemas/helpers/getIdsAndLabels";
import { dispatchCustomEvent } from "dws-filters/hooks/useEventBus";
import { CustomEventType } from "dws-filters/types/CustomEventType";
import format from "date-fns/format";
var ActionNameSchema = z.union([
    z.literal("SET_FILTER"),
    z.literal("RESET_FILTERS"),
    z.literal("SET_FILTERS"),
]);
var filterReducerActionSchema = z.object({
    type: ActionNameSchema,
    payload: z
        .object({
        filterName: FilterNameSchema,
        value: FiltersSchemaUnion,
        userInteracted: z.boolean().optional(),
    })
        .optional(),
    filteData: FiltersSchema.optional(),
});
export function emptyFilter() {
    return {
        location: null,
        area: null,
        subarea: null,
        equipment: null,
        machine: null,
        checklist: null,
        shift: null,
        selectedDate: new Date()
    };
}
export function isEmptyFilter(filter) {
    return [
        "location",
        "area",
        "subarea",
        "equipment",
        "machine",
        "checklist",
        "shift",
    ].every(function (key) { return !filter[key]; });
}
export function urlParamsFilter(params) {
    var keyCount = new Set(params.keys()).size;
    if (keyCount <= 0)
        return emptyFilter();
    return Object.keys(emptyFilter()).reduce(function (acc, key) {
        var _a;
        var windowURLparam = params.get(key);
        if (!windowURLparam)
            return acc;
        var isDate = /^\d{4}-\d{2}-\d{2}$/.test(windowURLparam);
        if (isDate) {
            acc[key] = new Date(Date.parse(windowURLparam));
            return acc;
        }
        var id = getIdsAndLabels(key).id;
        acc[key] = (_a = {}, _a[id] = parseInt(windowURLparam), _a);
        return acc;
    }, {});
}
export function localStorageFilters() {
    var filters = localStorage.getItem("filters");
    if (!filters)
        return emptyFilter();
    return JSON.parse(filters);
}
export function filterReducer(state, action) {
    var _a;
    state = FiltersSchema.parse(state);
    action = filterReducerActionSchema.parse(action);
    var dependantFilters = Object.entries(dependencies)
        .filter(function (_a) {
        var _b;
        var value = _a[1];
        return value.includes((_b = action.payload) === null || _b === void 0 ? void 0 : _b.filterName);
    })
        .map(function (k) { return k[0]; });
    var dependantFiltersState = dependantFilters.reduce(function (acc, curr) {
        acc[curr] = null;
        return acc;
    }, {});
    var type = action.type, payload = action.payload, filteData = action.filteData;
    var branches = {
        SET_FILTER: __assign(__assign(__assign({}, state), (_a = {}, _a[payload === null || payload === void 0 ? void 0 : payload.filterName] = payload === null || payload === void 0 ? void 0 : payload.value, _a)), dependantFiltersState),
        RESET_FILTERS: emptyFilter(),
        SET_FILTERS: filteData,
    };
    var result = branches[type];
    return result;
}
export var FilterSelectionContext = createContext(null);
export function useFilterSelectionContext() {
    var context = useContext(FilterSelectionContext);
    return context;
}
export function FilterSelectionProvider(_a) {
    var children = _a.children, initialValue = _a.initialValue, _b = _a.initial, initial = _b === void 0 ? emptyFilter() : _b;
    var _c = useReducer(filterReducer, initial), state = _c[0], dispatch = _c[1];
    var stringifiedState = JSON.stringify(state);
    var _d = useState(false), userInteraction = _d[0], setUserInteraction = _d[1];
    var setFilter = function (filterName, value, userInteracted) {
        if (userInteracted === void 0) { userInteracted = false; }
        dispatch({ type: 'SET_FILTER', payload: { filterName: filterName, value: value, userInteracted: userInteracted } });
        setUserInteraction(userInteracted);
    };
    function resetFilters() {
        dispatch({ type: "RESET_FILTERS" });
    }
    var value = useMemo(function () { return initialValue !== null && initialValue !== void 0 ? initialValue : { filters: state, setFilter: setFilter, resetFilters: resetFilters, userInteracted: userInteraction }; }, [state, initialValue, userInteraction]);
    useEffect(function () {
        dispatchCustomEvent({
            type: CustomEventType.FILTERS_CHANGED,
            payload: state,
        });
    }, []);
    useEffect(function () {
        var searchParams = new URLSearchParams();
        Object.entries(state).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (!value)
                return;
            if (value instanceof Date) {
                return searchParams.set(key, format(value || new Date(), "yyyy-MM-dd"));
            }
            var id = getIdsAndLabels(key).id;
            return searchParams.set(key, value[id]);
        });
        window.history.replaceState({}, "", "?".concat(searchParams.toString()));
        localStorage.setItem("filters", stringifiedState);
        dispatchCustomEvent({
            type: CustomEventType.FILTERS_CHANGED,
            payload: state,
        });
    }, [stringifiedState]);
    return (_jsx(FilterSelectionContext.Provider, __assign({ value: value }, { children: children })));
}
