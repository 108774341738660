var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useFilterSelectionContext } from "dws-filters/contexts/filterSelection/filtersSelectionContext";
import { FilterOptionsProvider } from "dws-filters/contexts/filterOptions/filterOptionsContext";
import { Select } from "../select/Select";
import { FiltersSchema } from "shared-schemas/filters";
import { Collapsible, CollapsibleItem, Icon, Row, Col, } from "@dws/dws-styleguide";
import { useTranslation } from "@dws/dws-profile";
var getCurrentShift = function (filters) {
    var _a;
    var currentTime = new Date().setSeconds(0, 0);
    var selectedDate = new Date(filters.selectedDate);
    var currentDay = selectedDate.getDay();
    currentDay = currentDay === 0 ? 7 : currentDay;
    var currentShiftConfig = Array.isArray((_a = filters.shiftConfig) === null || _a === void 0 ? void 0 : _a.shiftWeekDayConfig) ? filters.shiftConfig.shiftWeekDayConfig.find(function (_a) {
        var weekday = _a.weekday;
        return weekday === currentDay;
    }) : null;
    var currentShift = null;
    if (currentShiftConfig && Array.isArray(currentShiftConfig.shifts)) {
        currentShift = currentShiftConfig.shifts.find(function (_a) {
            var start = _a.start, end = _a.end;
            var _b = start.split(':').map(Number), startH = _b[0], startM = _b[1];
            var _c = end.split(':').map(Number), endH = _c[0], endM = _c[1];
            var startTime = new Date(selectedDate).setHours(startH, startM, 0, 0);
            var endTime = new Date(selectedDate).setHours(endH < startH ? endH + 24 : endH, endM, 0, 0);
            return currentTime >= startTime && currentTime <= endTime;
        }) || filters.shift;
    }
    return currentShift;
};
export function Filter(_a) {
    var httpClient = _a.httpClient, initialOptions = _a.initialOptions;
    var t = useTranslation().t;
    var _b = useFilterSelectionContext(), filters = _b.filters, setFilter = _b.setFilter, userInteracted = _b.userInteracted;
    var filterKeys = Object.keys(filters);
    if (!userInteracted) {
        var currentShift = getCurrentShift(filters);
        currentShift !== null && (filters.shift = currentShift);
    }
    var extractZodName = function (filterName) {
        var _a;
        return (_a = FiltersSchema.shape[filterName]) === null || _a === void 0 ? void 0 : _a._def.innerType._def.innerType._def.typeName;
    };
    return (_jsx("div", __assign({ className: "filters-container accordion-wrapper", id: "filter", "data-testid": "filter-entrypoint" }, { children: _jsx(Collapsible, __assign({ accordion: true }, { children: _jsx(CollapsibleItem, __assign({ expanded: true, header: _jsx("div", __assign({ title: t("filters.description") }, { children: t("filters.title") })), icon: _jsxs(_Fragment, { children: [_jsx(Icon, __assign({ className: "is-open" }, { children: "expand_less" })), _jsx(Icon, __assign({ className: "is-closed" }, { children: "expand_more" }))] }), node: "div" }, { children: _jsxs("div", __assign({ className: "accordion-content is-flex-column" }, { children: [_jsx("div", __assign({ className: "acc-body-subtitle" }, { children: t("filters.description") })), _jsx("div", __assign({ className: "inputs-container" }, { children: _jsx(Row, { children: filterKeys.map(function (filterName, index) {
                                    var typeName = extractZodName(filterName);
                                    var initialSelectOptions = initialOptions[filterName];
                                    if (typeName != "ZodDate" &&
                                        !Array.isArray(initialSelectOptions))
                                        return null;
                                    return (_jsx(FilterOptionsProvider, __assign({ httpClient: httpClient, filterKey: filterName, initial: {
                                            options: initialSelectOptions || [],
                                            loading: false,
                                        } }, { children: _jsx(Col, __assign({ xl: 3, l: 3, m: 4, s: 12 }, { children: _jsx(Select, { dataType: typeName, onChange: setFilter, filterName: filterName, selectedValue: filters[filterName] }) })) }), filterName));
                                }) }) }))] })) })) })) })));
}
