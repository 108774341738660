var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useMemo, useReducer, useRef, } from "react";
import { z } from "zod";
import { FilterOptionsSchema, FiltersSchemaUnion, } from "shared-schemas/filters/filter.schema";
import { getIdsAndLabels } from "shared-schemas/helpers/getIdsAndLabels";
import { useFilterSelectionContext } from "../filterSelection/filtersSelectionContext";
import { dependencies, filterEffect, usecases } from "./effects";
var ActionNameSchema = z.union([
    z.literal("RESET_OPTIONS"),
    z.literal("SET_OPTIONS"),
]);
var filterReducerActionSchema = z.object({
    type: ActionNameSchema,
    payload: z.union([FiltersSchemaUnion.array().nullable(), FiltersSchemaUnion.nullable()]),
});
export var initialState = {
    options: [],
    loading: false,
};
export var FilterOptionsContext = createContext(initialState);
export function filterOptionsReducer(state, action) {
    FilterOptionsSchema.parse(state);
    action = filterReducerActionSchema.parse(action);
    var type = action.type, payload = action.payload;
    var branches = {
        SET_OPTIONS: payload,
        RESET_OPTIONS: [],
    };
    var result = branches[type];
    return result;
}
export function FilterOptionsProvider(_a) {
    var _this = this;
    var children = _a.children, httpClient = _a.httpClient, initial = _a.initial, filterKey = _a.filterKey;
    var isMountedRef = useRef(false);
    var _b = useReducer(filterOptionsReducer, initial.options), options = _b[0], dispatch = _b[1];
    var loadingRef = useRef(initial.loading);
    var _c = useFilterSelectionContext(), filters = _c.filters, setFilter = _c.setFilter;
    var dependencyArray = dependencies[filterKey]
        .map(function (key) { return [key, filters[key]]; })
        .map(function (_a) {
        var _b, _c;
        var key = _a[0], value = _a[1];
        var id = getIdsAndLabels(key).id;
        return (_c = ((_b = value === null || value === void 0 ? void 0 : value[id]) !== null && _b !== void 0 ? _b : value)) === null || _c === void 0 ? void 0 : _c.toString();
    });
    var getOptions = function (filters) { return __awaiter(_this, void 0, void 0, function () {
        var usecase, getter, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    loadingRef.current = true;
                    usecase = usecases[filterKey];
                    getter = usecase(httpClient);
                    return [4 /*yield*/, getter(filters)];
                case 1:
                    result = _a.sent();
                    loadingRef.current = false;
                    return [2 /*return*/, result];
            }
        });
    }); };
    var setOptions = function (payload) {
        return dispatch({ type: "SET_OPTIONS", payload: payload });
    };
    useEffect(function () {
        if (isMountedRef.current) {
            filterEffect({
                getOptions: getOptions,
                setOptions: setOptions,
                lastFilters: filters,
                matchingKey: filterKey,
                setFilter: setFilter,
            });
        }
        isMountedRef.current = true;
    }, dependencyArray);
    var value = useMemo(function () { return ({
        loading: loadingRef.current,
        options: options,
    }); }, [options]);
    return (_jsx(FilterOptionsContext.Provider, __assign({ value: value }, { children: children }), "" + isMountedRef.current));
}
export function useFilterOptionsContext() {
    var context = useContext(FilterOptionsContext);
    return context;
}
