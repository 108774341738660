var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getLocationsUseCase, getAreasUseCase, getEquipmentsUseCase, getMachinesUseCase, getChecklistsUseCase, getShiftsUseCase, getSubareasUseCase, } from "dws-filters/modules/filter/domain/usecases";
export function filterEffect(_a) {
    var getOptions = _a.getOptions, setOptions = _a.setOptions, lastFilters = _a.lastFilters, matchingKey = _a.matchingKey, setFilter = _a.setFilter;
    return __awaiter(this, void 0, void 0, function () {
        var options, lastSelection, filter;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getOptions(lastFilters)];
                case 1:
                    options = _b.sent();
                    if (!Array.isArray(options))
                        return [2 /*return*/];
                    setOptions(options);
                    lastSelection = lastFilters[matchingKey];
                    filter = filterSelector(options, lastSelection);
                    return [2 /*return*/, setFilter(matchingKey, filter)];
            }
        });
    });
}
export function filterSelector(options, lastSelection) {
    var equalOption = options === null || options === void 0 ? void 0 : options.find(function (option) { return JSON.stringify(option) === JSON.stringify(lastSelection); });
    return equalOption || options[0] || null;
}
export function findMatchingDay(weekday) {
    return function (day) { return day.weekday === weekday; };
}
export var usecases = {
    area: getAreasUseCase,
    subarea: getSubareasUseCase,
    equipment: getEquipmentsUseCase,
    machine: getMachinesUseCase,
    checklist: getChecklistsUseCase,
    location: getLocationsUseCase,
    selectedDate: function (_) { return function (__) { return Promise.resolve([]); }; },
    shift: function (client) { return function (filters) { return __awaiter(void 0, void 0, void 0, function () {
        var shiftConfig, weekday, searchFunc;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getShiftsUseCase(client)(filters)];
                case 1:
                    shiftConfig = (_c.sent())[0];
                    weekday = ((_a = filters.selectedDate) === null || _a === void 0 ? void 0 : _a.getDay()) || 7;
                    searchFunc = findMatchingDay(weekday);
                    return [2 /*return*/, ((_b = shiftConfig === null || shiftConfig === void 0 ? void 0 : shiftConfig.shiftWeekDayConfig.find(searchFunc)) === null || _b === void 0 ? void 0 : _b.shifts) || []];
            }
        });
    }); }; },
    shiftConfig: function (client) { return function (filters) { return __awaiter(void 0, void 0, void 0, function () {
        var shiftConfig;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getShiftsUseCase(client)(filters)];
                case 1:
                    shiftConfig = (_a.sent())[0];
                    return [2 /*return*/, shiftConfig];
            }
        });
    }); }; },
};
export var dependencies = {
    location: [],
    area: ["location"],
    subarea: ["area"],
    equipment: ["subarea"],
    machine: ["equipment"],
    checklist: ["area", "subarea"],
    shift: ["selectedDate", "area", "location"],
    selectedDate: [],
    shiftConfig: ["area", "location"],
};
