var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FiltersSchema } from "shared-schemas/filters";
import { getIdsAndLabels } from "shared-schemas/helpers/getIdsAndLabels";
import { useFilterOptionsContext } from "dws-filters/contexts/filterOptions/filterOptionsContext";
import { DWSSelect, DatePicker } from "@dws/dws-styleguide";
import { parseFromJSON } from "shared-schemas/helpers/parseFromJSON";
import { z } from "zod";
import { useTranslation } from "@dws/dws-profile";
export function Select(props) {
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var language = i18n.language;
    var dataType = props.dataType, onChange = props.onChange, filterName = props.filterName, selectedValue = props.selectedValue, initialValue = props.initialValue;
    var _b = useFilterOptionsContext(), options = _b.options, loading = _b.loading;
    var id = "select-".concat(filterName);
    var label = "";
    if (loading && dataType == "ZodObject")
        return (_jsx("select", __assign({ disabled: true, "data-testid": id }, { children: _jsx("option", { children: "Loading..." }) })));
    function onDateChange(e) {
        var value = e.target.value;
        var date = new Date(value);
        var shape = FiltersSchema.shape[filterName];
        var payload = shape.parse(date);
        onChange(filterName, payload, true);
    }
    if (dataType === "ZodDate") {
        return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: id }, { children: t("filters.".concat(filterName)) })), _jsx(DatePicker, { name: filterName, onDateChange: onDateChange, value: selectedValue })] }));
    }
    (label = getIdsAndLabels(filterName, language).label);
    function onSelectChange(value) {
        var shape = FiltersSchema.shape[filterName];
        var schema = z.union([shape, z.any()]);
        var parser = parseFromJSON(schema);
        var payload = parser(value);
        onChange(filterName, payload, true);
    }
    var internallySelectedValue = selectedValue || (initialValue === null || initialValue === void 0 ? void 0 : initialValue[0]) || options[0];
    if (internallySelectedValue && !internallySelectedValue[label]) {
        (label = getIdsAndLabels(filterName).label);
    }
    if (!selectedValue && internallySelectedValue)
        onSelectChange(internallySelectedValue);
    return (_jsxs(_Fragment, { children: [_jsx("label", __assign({ htmlFor: "select-".concat(filterName) }, { children: t("filters.".concat(filterName)) })), _jsx(DWSSelect, { id: id, options: options, label: label, onChange: onSelectChange, value: internallySelectedValue, placeholder: filterName })] }));
}
